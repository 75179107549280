import { Link, graphql, useStaticQuery } from 'gatsby';
import React, { Fragment } from 'react';
import Seo from '../components/layout/SEO';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import FaBullseye from '../assets/faBullseye.svg';
import Layout from '../components/layout/LayoutInner';
import InnerHead from '../components/layout/InnerHead';
import MainQtrLeft from '../components/layout/MainQtrLeft';
import ImageBlock from '../components/elements/ImageBlock';
import OurCases from '../components/home/OurCases';
import Pitch from '../components/home/Pitch';
import Testimonials from '../components/home/Testimonials';

const AttorneyTemplate = ({ data }) => {
  const profileImage = getImage(data.mdx.frontmatter.hero_image);

  const practiceAreas = useStaticQuery(graphql`
    {
      allMdx(
        filter: {fileAbsolutePath: {glob: "/home/dan/dreyer/src/data/practice-areas/*"}}
        sort: {fields: frontmatter___order, order: ASC}
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            slug
          }
        }
      }
      allResultsJson(limit: 3) {
        edges {
          node {
            award
            category
            id
            narrative
          }
        }
      }
      allTestimonialsJson(limit: 4) {
        edges {
          node {
            id
            author
            excerpt
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title={`About ${data.mdx.frontmatter.title}`}
        canonical={data.mdx.frontmatter.canon}
        description={data.mdx.excerpt}
        article='article'
      />
      <InnerHead title={`About ${data.mdx.frontmatter.title}`} />
      <main className="attorney-page">
        <MainQtrLeft>
          <div>
            <ImageBlock>
              <GatsbyImage image={profileImage} alt={data.mdx.frontmatter.title} />
            </ImageBlock>
            <div className="attorney-page--title">
              <h1>{data.mdx.frontmatter.title}</h1>
              <h2>{data.mdx.frontmatter.role}</h2>
            </div>
            <div className="attorney-page--contact">
              <p>
                <span>Email:</span>
                {data.mdx.frontmatter.email}
              </p>
              <p>
                <span>Phone:</span>
                {data.mdx.frontmatter.phone}
              </p>
            </div>
          </div>
          <div className="attorney-page--bio">
            <MDXRenderer>
              {data.mdx.body}
            </MDXRenderer>
            <h3>Practice Areas</h3>
            <ul className="attorney-page--practice-areas">
              {practiceAreas.allMdx.edges.map(({ node }) => {
                return (
                  <Fragment>
                    <li>
                      <FaBullseye />
                      <Link to={`/practice-areas/${node.slug}`}>
                        {node.frontmatter.title}
                      </Link>
                    </li>
                  </Fragment>
                )
              })}
            </ul>
          </div>
        </MainQtrLeft>
      </main>
      <Testimonials data={practiceAreas.allTestimonialsJson} />
      <OurCases data={practiceAreas.allResultsJson} />
      <Pitch />
    </Layout>
  )
}

export default AttorneyTemplate
