import { graphql } from 'gatsby';
import React, { Fragment } from 'react';

import AttorneyTemplate from '../../templates/attorney-template'
import NewsTemplate from '../../templates/news-template'
import PracticeTemplate from '../../templates/practice-template'

const MdxPage = ({
  data,
  data: {
    mdx: {
      frontmatter: { datum },
    },
  },
}) => {
  const templates = {
    attorneys: <AttorneyTemplate data={data} />,
    blog: <NewsTemplate data={data} />,
    practiceAreas: <PracticeTemplate data={data} />,
  }

  return (
    <Fragment>
      {templates[datum] ? templates[datum] : null}
    </Fragment>
  );
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        datum
        dir
        role
        email
        phone
        date(formatString: "MMMM Do, YYYY")
        category
        canon
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body,
      slug,
      excerpt
    }
  }    
`

export default MdxPage
